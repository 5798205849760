import React, { useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";

export default function BulkUploadExSheetBtn({
  btnText,
  setSelectedBulkUploadFile,
  btnLoading,
  handleDownloadTemplate,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedBulkUploadFile(file);
      event.target.value = "";
    }
  };

  // Handle file upload
  const handleUploadClick = () => {
    fileInputRef.current.click();
    setIsDropdownOpen(false);
  };

  return (
    <div className="bulk-upload-wrapper">
      <button className="bulkUploadExSheetBtn" onClick={handleButtonClick}>
        {btnLoading && (
          <span
            className="spinner-border mr-1"
            role="status"
            aria-hidden="true"
            style={{
              width: "20px",
              height: "20px",
              borderWidth: "3px",
            }}
          ></span>
        )}
        {btnText}
        <div className="ml-2 d-inline">
          <FaAngleDown />
        </div>
      </button>
      {isDropdownOpen && (
        <div className="bulk-dropdown-btn">
          <button onClick={handleUploadClick}>
            <div className={"d-inline mr-2"}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.54061 0.199698C4.66848 0.0718363 4.8419 0 5.02273 0H10.4773C11.607 0 12.5227 0.915784 12.5227 2.04545V5.45455C12.5227 5.8311 12.2175 6.13636 11.8409 6.13636C11.4643 6.13636 11.1591 5.8311 11.1591 5.45455V2.04545C11.1591 1.6689 10.8538 1.36364 10.4773 1.36364H5.70455V4.77273C5.70455 5.14928 5.3993 5.45455 5.02273 5.45455H1.61364V12.9545C1.61364 13.3311 1.9189 13.6364 2.29545 13.6364H2.97727C3.35383 13.6364 3.65909 13.9416 3.65909 14.3182C3.65909 14.6948 3.35383 15 2.97727 15H2.29545C1.16578 15 0.25 14.0843 0.25 12.9545V4.77273C0.25 4.5919 0.321836 4.41848 0.449698 4.29061L4.54061 0.199698ZM2.57787 4.09091H4.34091V2.32787L2.57787 4.09091ZM11.1591 7.5C11.5357 7.5 11.8409 7.80525 11.8409 8.18182V12.2727H13.8864C14.2629 12.2727 14.5682 12.578 14.5682 12.9545C14.5682 13.3311 14.2629 13.6364 13.8864 13.6364H11.1591C10.7825 13.6364 10.4773 13.3311 10.4773 12.9545V8.18182C10.4773 7.80525 10.7825 7.5 11.1591 7.5ZM6.25934 7.78555C6.04048 7.47907 5.61468 7.40816 5.30827 7.62702C5.00183 7.84589 4.93086 8.27168 5.14975 8.57809L6.57121 10.5682L5.14975 12.5583C4.93086 12.8647 5.00183 13.2905 5.30827 13.5093C5.61468 13.7282 6.04048 13.6573 6.25934 13.3508L7.40909 11.7413L8.55884 13.3508C8.7777 13.6573 9.2035 13.7282 9.50991 13.5093C9.81639 13.2905 9.8873 12.8647 9.66843 12.5583L8.24698 10.5682L9.66843 8.57809C9.8873 8.27168 9.81639 7.84589 9.50991 7.62702C9.2035 7.40816 8.7777 7.47907 8.55884 7.78555L7.40909 9.39511L6.25934 7.78555Z"
                  fill="#F3F8FF"
                />
              </svg>
            </div>
            Upload
          </button>
          <button onClick={handleDownloadTemplate}>
            <div className={"d-inline mr-2"}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3333 12.3337H11.34M11.6 10.3337H12C12.6213 10.3337 12.9319 10.3337 13.1769 10.4351C13.5036 10.5705 13.7632 10.8301 13.8985 11.1567C14 11.4018 14 11.7124 14 12.3337C14 12.9549 14 13.2655 13.8985 13.5106C13.7632 13.8373 13.5036 14.0969 13.1769 14.2322C12.9319 14.3337 12.6213 14.3337 12 14.3337H4C3.37875 14.3337 3.06812 14.3337 2.82309 14.2322C2.49639 14.0969 2.23682 13.8373 2.10149 13.5106C2 13.2655 2 12.9549 2 12.3337C2 11.7124 2 11.4018 2.10149 11.1567C2.23682 10.8301 2.49639 10.5705 2.82309 10.4351C3.06812 10.3337 3.37875 10.3337 4 10.3337H4.4M8 11.0003V3.66699M8 11.0003L6 9.00033M8 11.0003L10 9.00033"
                  stroke="#F3F8FF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>{" "}
            Download Template
          </button>
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".xlsx, .xlsm, .xls, .xlsb, .xltx, .xlt, .csv"
        onChange={handleFileChange}
      />
    </div>
  );
}
