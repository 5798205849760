import React from "react";
import WastInfoList from "../components/wasteInfo/WastInfoList";
import UserGarbageList from "../components/UserGarbage/UserGarbageList";

export default function UserGarbage() {
  return (
    <>
      <UserGarbageList />
    </>
  );
}
