import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import { isEven } from "../../constants/Helper/NumbersFunction";
import Loader from "../../commanComponents/Loader";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import Select from "react-select";
function UserGarbageList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [pageUpdated, setPageUpdated] = useState("");
  const [westInfo, setWestInfo] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [wardList, setWardList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);

  const { data, isFetching, error } = useQuery(
    ["wasteInfo", page, debouncedSearchTerm, pageUpdated],
    () => commonApi.getZoneList(page, searchString),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          const options = data.result.map((zone) => ({
            value: zone._id,
            label: zone.name,
          }));
          setZoneList(options);
        } else if (data?.status === "JWT_INVALID") {
          toast.error(`Failed : ${data?.message}`);
        }
        if (data?.status == "FAILED") {
          setWestInfo([]);
        }
      },
      onError: (error) => {
        toast.error(`Failed : ${error?.message}`);
      },
    }
  );
  const { data: userGarbageData, isFetching: userGarbageIsFetching } = useQuery(
    ["UserGarbageInfo", page, pageUpdated, selectedZone, selectedWard],
    () =>
      commonApi.getUserGarbageList(
        page,
        searchString,
        selectedZone?.value,
        selectedWard?.value
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          setWestInfo(data?.garbage);
        } else if (data?.status === "JWT_INVALID") {
          toast.error(`Failed : ${data?.message}`);
        }
        if (data?.status === "FAILED") {
          setWestInfo([]);
        }
      },
      onError: (error) => {
        toast.error(`Failed : ${error?.message}`);
      },
    }
  );

  const getWardList = async () => {
    try {
      const res = await commonApi.getWardByZoneId(selectedZone?.value);
      if (res?.status === "SUCCESS") {
        const options = res.zone.map((ward) => ({
          value: ward._id,
          label: ward.name,
        }));
        setWardList(options);
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Log In!");
        setWardList([]);
      } else {
        setWardList([]);
        toast.error(res?.message || "Something Went Wrong!");
      }
    } catch (error) {
      setWardList([]);
      toast.error(error?.message || "Something Went Wrong!");
    }
  };

  useEffect(() => {
    if (selectedZone) {
      if (selectedZone?.value) {
        getWardList();
      }
      setSelectedWard("");
    }
  }, [selectedZone]);
  useEffect(() => {
    if (!checkUserTabAccess("Garbage_Info") && userRole() !== "zonal") {
      toast.info("You do not have permission to access Garbage Info");
      navigate(-1); // redirect to previous page if user dont not have Tab Access
      return;
    }
  }, []);

  return (
    <div>
      {isFetching && <Loader />}
      <div className="search_btn_wrapper">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginRight: 20,
            }}
          >
            <Select
              // isLoading={zoneLoading}
              options={zoneList}
              onChange={(selected) => {
                console.log("selected", selected);
                setSelectedZone(selected);
              }}
              placeholder="Select a Zone"
              value={selectedZone ? selectedZone : ""}
              styles={{ zIndex: 1 }}
            />
          </div>
          <div>
            <Select
              options={[{ label: "Select All", value: "" }, ...wardList]}
              onChange={(selected) => {
                setSelectedWard(selected);
              }}
              placeholder="Select a Ward"
              value={selectedWard ? selectedWard : ""}
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper big_Shadow">
        <div
          className="table-responsive position-relative tablemain"
          style={{ overflowY: "auto", height: "58vh" }}
        >
          <table className="table position-relative w-100">
            <thead
              className="rounded-pill w-100 bg_Table_Header border-0"
              style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
            >
              <tr>
                <th scope="col" className="col_100px">
                  Sr. No
                </th>
                <th scope="col" className="col_100px">
                  House Id
                </th>
                <th scope="col" className="col_150px">
                  Dry Waste
                </th>
                <th scope="col" className="col_150px">
                  Wet Waste
                </th>
                <th scope="col" className="col_200px">
                  Zone Name
                </th>
                <th scope="col" className="col_250px">
                  Ward Name
                </th>
              </tr>
            </thead>
            <tbody>
              {westInfo?.length > 0 &&
                westInfo?.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${isEven(index + 1) && "Table_Data"}`}
                    >
                      <th scope="row"> {PaginationSrNo(page, index)}</th>
                      <td className="text-capitalize">
                        {data?.houseId?.plotNo ? data?.houseId?.plotNo : "--"}
                      </td>
                      <td className="text-capitalize">
                        {data?.dryWasteCollected
                          ? data?.dryWasteCollected
                          : "--"}
                      </td>
                      <td className="text-capitalize">
                        {data?.wetWasteCollected
                          ? data?.wetWasteCollected
                          : "--"}
                      </td>
                      <td className="text-capitalize">
                        {data?.zoneId?.name ? data?.zoneId?.name : "--"}
                      </td>
                      <td className="text-capitalize">
                        {data?.wardId?.name ? data?.wardId?.name : "--"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {westInfo?.length === 0 && (
            <h6 className="DataNotFoundText">No Waste Info Found...</h6>
          )}
        </div>
      </div>

      <div className="mt-2">
        <PaginationButton
          page={page}
          setPage={setPage}
          totalPages={data?.totalPages || 1}
        />
      </div>
    </div>
  );
}

export default UserGarbageList;
