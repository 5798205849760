import axios from "axios";
import Cookies from "js-cookie";

// Fetches the base server URL based on the environment
const developmentURL = process.env.REACT_APP_BASE_SERVER_URL;
// const developmentURL = "http://192.168.1.57:3020";
const nodeEnvironment = process.env.REACT_APP_NODE_ENV;

// Function to get the token from cookies
export const getToken = () => {
  const token = Cookies.get(process.env.REACT_APP_LOGIN_KEY || "loginToken");
  return token ? JSON.parse(token).token : "";
};

// Retrieves the server URL for development environment
export function getServerUrl() {
  if (nodeEnvironment === "development") {
    return developmentURL;
  } else {
    return developmentURL; // You may want to return a different URL for production
  }
}

export const commonApi = {
  // ----------------------- Authentication -------------------- //

  login: async function (data) {
    try {
      const res = await axios.post(`${getServerUrl()}/admin/login`, data);
      return res.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  resetPassword: async function (data) {
    try {
      const res = await axios.post(
        `${getServerUrl()}/admin/reset-password`,
        data
      );
      return res.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  generateOTP: async function (userId, mobile) {
    try {
      const res = await axios.post(`${getServerUrl()}/admin/forget-password`, {
        userId,
        mobile,
      });
      return res.data;
    } catch (error) {
      console.error("Error in OTP Generation:", error);
      throw error;
    }
  },
  verifyOTP: async function (otp, userId, mobile) {
    try {
      const res = await axios.post(`${getServerUrl()}/admin/verify-otp`, {
        otp,
        userId,
        mobile,
      });
      return res.data;
    } catch (error) {
      console.error("Error in OTP Generation:", error);
      throw error;
    }
  },
  changeUserPassword: async function (userId, newPassword, confirmPassword) {
    try {
      const res = await axios.post(
        `${getServerUrl()}/admin/change-user-password`,
        {
          userId,
          newPassword,
          confirmPassword,
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error in OTP Generation:", error);
      throw error;
    }
  },
  // Live tracking API
  liveTrackingFilters: async function (formData) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/track/get-track-list`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  // Live tracking API
  liveTrackingGetAllHouses: async function (formData) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/survey/get-all-houses-coordinates`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  // Live tracking API
  liveTrackingGetAllDynamoHouses: async function (formData) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/garbage/get-track-wise-house-list-dyanamo-db`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  // Live tracking API
  getATrack: async function (formData) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/track/get-track-wise-coordinates`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  // Live tracking API
  getAHouseGarbageData: async function (formData) {
    try {
      console.log(formData);
      const response = await axios.post(
        `${getServerUrl()}/garbage/get-monthly-collected-garbage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  // ----------------------- Department Api Calls -------------------- //
  getDepartmentList: async function (page, searchString) {
    const updatedPage = searchString ? 1 : page; //if searchstring available then page will be 1 for global search behavior
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/get-department-list`,
        { page: updatedPage, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getActiveDepartmentList: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/department/get-active-department`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  createDepartment: async function (name) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/create-department`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating department:", error);
      throw error;
    }
  },
  getDepartmentById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/get-department-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("No Department Found:", error);
      throw error;
    }
  },
  updateDepartment: async function (id, name) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/update-department`,
        { id, name },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("No Department Found:", error);
      throw error;
    }
  },
  changeDepartmentStatus: async function (id, token) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/change-department-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Changing Department Status:", error);
      throw error;
    }
  },

  // ----------------------- Designation API Calls -------------------- //

  getDesignationList: async function (page, searchString, signal) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/get-designation-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  },
  getDesignationListByDepartment: async function (id) {
    try {
      const response = await axios.get(
        `${getServerUrl()}/user/department-wise-designation?departmentId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating department:", error);
      throw error;
    }
  },
  getDesignationById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/get-designation-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("No Department Found:", error);
      throw error;
    }
  },
  createDesignation: async function (name, departmentId, token) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/create-designation`,
        { name, departmentId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating department:", error);
      throw error;
    }
  },
  changeDesignationStatus: async function (id, token) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/change-designation-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Changing Department Status:", error);
      throw error;
    }
  },
  updateDesignation: async function (id, name, departmentId) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/update-designation`,
        { id, name, departmentId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("No Department Found:", error);
      throw error;
    }
  },

  // ----------------------- Department Api Calls -------------------- //
  updateDepartment: async function (id, name) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/update-department`,
        { id, name },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("No Department Found:", error);
      throw error;
    }
  },

  // ----------------------- Designation API Calls -------------------- //

  getDesignationList: async function (page, searchString, signal) {
    const updatedPage = searchString ? 1 : page; //if searchstring available then page will be 1 for global search behavior
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/get-designation-list`,
        { page: updatedPage, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  },
  createDesignation: async function (name, departmentId) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/create-designation`,
        { name, departmentId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating department:", error);
      throw error;
    }
  },
  changeDesignationStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/designation/change-designation-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Changing Department Status:", error);
      throw error;
    }
  },

  changeDepartmentStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/department/change-department-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Changing Department Status:", error);
      throw error;
    }
  },

  // --------------------------- User Profile Api ----------------------------------------

  getUserProfileData: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/get-user-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("No data Found:", error);
      throw error;
    }
  },

  //------------------------ User Management ---------------------//

  getUserList: async function (
    type,
    page,
    searchString,
    wardId,
    periodOfContract
  ) {
    try {
      type = type?.map((ele) => ele?.value);
      const response = await axios.post(
        `${getServerUrl()}/user/user-type-wise-list`,
        { type, page, searchString, wardId, periodOfContract },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  //------------------------ Announcements ---------------------//

  getAnnouncementList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/announcement/get-announcement-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  updateAnnouncementStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/announcement/change-announcement-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  getAnnouncementDetails: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/announcement/get-announcement-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  updateAnnouncementDetails: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/announcement/update-announcement?isFileAttached=true`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  addAnnouncementDetails: async function (tataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/announcement/add-announcement?isFileAttached=true`,
        tataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  changeUserStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/change-user-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Changing Department Status:", error);
      throw error;
    }
  },
  getDepartmentWiseUsers: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/department-wise-user`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Changing Department Status:", error);
      throw error;
    }
  },

  //----------------------- Garbage Info ---------------------- //
  getGarbageInfoList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/get-waste-info-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getGarbageInfoById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/get-waste-info-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  addGarbageInfo: async function (wasteType, unit, perPersonExpectedWaste) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/add-waste-info`,
        { wasteType, unit, perPersonExpectedWaste },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  updateGarbageInfo: async function (
    id,
    wasteType,
    unit,
    perPersonExpectedWaste
  ) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/update-waste-info`,
        { id, wasteType, unit, perPersonExpectedWaste },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // ------------------------ Garbage Collection ------------------------//

  getGarbageCollectionInfo: async function (
    page,
    tabName,
    searchString,
    zoneId,
    wardId,
    startDate,
    endDate
  ) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/garbage/get-garbage-list-for-web`,
        {
          page,
          tabName,
          searchString,
          zoneId,
          wardId,
          fromDate: startDate,
          toDate: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getHouseGarbageCollectionList: async function (
    page,
    houseId,
    startDate,
    endDate
  ) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/garbage/get-house-garbage-list`,
        { page, houseId, startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  //------------------------ Dump Yard ---------------------//

  getDumpYardList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/dump-yard/get-dump-yard-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in get Dump Yard List:", error);
      throw error;
    }
  },

  updateDumpActiveStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/dump-yard/change-dump-yard-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error while update Dump Active Status:", error);
      throw error;
    }
  },
  addDumpYard: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/dump-yard/register-dump-yard`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error while add Dump Yard:", error);
      throw error;
    }
  },
  updateDumpYard: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/dump-yard/update-dump-yard`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error while add Dump Yard:", error);
      throw error;
    }
  },

  getDumpYardDetails: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/dump-yard/get-dump-yard-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  getActiveZones: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/zone/get-active-zone`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  //------------------------User Management ---------------------//

  createUser: async function (formData, isFileAttached = true) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/admin/create-user?isFileAttached=${isFileAttached}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  updateAdminUser: async function (formData, isFileAttached = true) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/update-user?isFileAttached=${isFileAttached}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": isFileAttached
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  updateAdminUserProfile: async function (formData) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/update-user-profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  updateAdminUserDocument: async function (formData, isFileAttached = true) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/update-user-documents?isFileAttached=${isFileAttached}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": isFileAttached
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  // ----------------------- Designation API Calls -------------------- //

  getActiveDesignationList: async function (signal) {
    try {
      const response = await axios.get(
        `${getServerUrl()}/designation/get-active-designation`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  },

  getDepartmentWiseUsers: async function (searchString) {
    try {
      const response = await axios.get(
        `${getServerUrl()}/user/department-wise-user?searchString=${searchString}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error In Getting Department:", error);
      throw error;
    }
  },

  //------------------------ Dump Yard ---------------------//

  getDumpYardList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/dump-yard/get-dump-yard-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  //  ----------------------------- Admin Zone Api Call -----------------------------//
  getZoneList: async function (page, searchString) {
    const updatedPage = searchString ? 1 : page; //if searchstring available then page will be 1 for global search behavior
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/get-zone-list`,
        { page: updatedPage, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getUserGarbageList: async function (page, searchString, zoneId, wardId) {
    const updatedPage = searchString ? 1 : page; //if searchstring available then page will be 1 for global search behavior
    try {
      const response = await axios.post(
        `${getServerUrl()}/garbage/get-user-garbage-list`,
        { page: updatedPage, zoneId, wardId, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  bulkUploadZones: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/upload-bulk-zone-data`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  downloadZoneTamplate: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/zone/download-zone-template`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getActiveZoneList: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/zone/get-active-zone`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getZoneById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/get-zone-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  addZone: async function (zone, zoneNo, address) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/register-zone`,
        { name: zone, zoneNo, address },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  updateZone: async function (id, zone, zoneNo, address) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/update-zone`,
        { id, name: zone, zoneNo, address },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  changeZoneStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/change-zone-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // -------------------------- Admin Ward List API Call ---------------- //

  getWardList: async function (page, searchString) {
    const updatedPage = searchString ? 1 : page; //if searchstring available then page will be 1 for global search behavior
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/get-ward-list`,
        { page: updatedPage, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  downloadWardTamplate: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/ward/download-ward-template`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  bulkUploadWards: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/upload-bulk-ward-data`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  fetchActiveZoneWiseWardList: async function (zoneIds) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/get-multiple-zone-wise-ward`,
        { zoneIds },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getWardById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/get-ward-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  getActiveWardList: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/ward/get-active-ward`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  addWard: async function (name, zoneId) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/create-ward`,
        { name, zoneId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  updateWard: async function (id, name, zoneId) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/update-ward`,
        { id, name, zoneId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  changeWardStatus: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/ward/change-ward-status`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  getWardByZoneId: async function (zoneId) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zone/get-zone-wise-ward`,
        { zoneId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // -------------------------- User Call API ------------------------------//
  getUserWithTabAccessById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/get-user-access-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  giveTabAccessToUser: async function (userId, tabAccess, isActive) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/user/give-user-tab-access`,
        { userId, tabAccess, isActive },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // ---------------------------- Grivance API Call -------------------//

  getComplaintList: async function (page, searchString, status, filterType) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/complaint/get-status-wise-complaint-list`,
        { page, searchString, status, filterType },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  getGrivanceById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/complaint/get-complaint-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  changeGrievanceStatus: async function (id, status, reply) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/complaint/change-complaint-status`,
        { id, status, reply },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // ------------------------------ Notifications ------------------------------//
  getNotifications: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/notification/get-notification-list`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },
  deleteNotification: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/notification/delete-notification`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // -------------------------- Admin Dashboard -----------------------------------

  getGrivanceData: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/admin-dashboard/status-wise-complaint-data`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getGrivanceDataforZonal: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/zonal-dashboard/status-wise-complaints`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getAdminDashboardCounts: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/admin-dashboard/basic-counts`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getAdminDashboardWasteCollection: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/admin-dashboard/day-month-wise-collection`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getZoneWiseCollection: async function (month) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/admin-dashboard/zone-wise-waste-collection`,
        { month },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  // ------------------------------ Track API CALL ----------------------------------------//
  getTrackList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/track/get-track-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying credentials:", error);
      throw error;
    }
  },

  // <<-------------------------------------------> Zanal API Call <------------------------------------------------------->>

  //------------------------ West Info ---------------------//

  getWestInfoList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/get-waste-info-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  addWestInfo: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/add-waste-info`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  updateWasteInfo: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/update-waste-info`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  geteWasteInfoById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/waste-info/get-waste-info-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  //------------------------ Survey Details APIs ---------------------//

  getSurveyDetailsList: async function (page, searchString, houseType) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/survey/get-survey-list`,
        { page, searchString, houseType },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getSurveyById: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/survey/get-survey-details-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  //------------------------ All Track ---------------------//

  getAllTrackList: async function (page, searchString, zoneId) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/track/get-track-list`,
        { page, searchString, zoneId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  //------------------------ All Track ---------------------//

  getAllMyGrievanceList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/complaint/my-grievance-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  createGrievance: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/complaint/create-complaint?isFileAttached=true`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  //------------------------ Zonal Dashboard ---------------------//

  getAllDashboardCounts: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/zonal-dashboard/total-waste-collection`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getContractorCollection: async function (month) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zonal-dashboard/contractor-collection`,
        { month },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  getActiveGarbage: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/waste-info/get-active-waste-info`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getTodaysCollection: async function () {
    try {
      const response = await axios.get(
        `${getServerUrl()}/zonal-dashboard/todays-wards-collection`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  get15DaysCollection: async function (dataToSend) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/zonal-dashboard/ward-wise-collection`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  //------------------------Transfer Requests ---------------------//

  getTransferReqList: async function (page, searchString) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/transfer/get-transfer-list`,
        { page, searchString },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getransferReqDetails: async function (id) {
    try {
      const response = await axios.post(
        `${getServerUrl()}/transfer/get-transfer-by-id`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};
